import { useEffect } from "react";
import { z } from "zod";
import { Form } from "../../../../../../../components/Form/Form.tsx";
import { useForm } from "../../../../../../../components/Form/FormContext.ts";
import { BaseClickableIcon } from "../../../../../../../components/Icon/BaseClickableIcon.tsx";
import { Popover } from "../../../../../../../components/Popover/Popover.tsx";
import { useUser } from "../../../../../../../hooks/useUser.ts";
import { useAppQuery } from "../../../../../../../http/useAppQuery.ts";
import { isUserAllowedTo } from "../../../../../../../types.ts";
import { addNullInput } from "../../../../../../../utils/validations.ts";
import type { Style } from "../../../../../../types.ts";
import { PresetButton } from "../../../../../components/PresetButton.tsx";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { GenerationBar } from "../../../../components/GenerationBar/GenerationBar.tsx";
import { GenerationRatioPresetSection } from "../../../../components/SettingsMenu/./GenerationRatioPresetSection.tsx";
import { CollapsableSettingsSectionWrapper } from "../../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { QualityPresetSection } from "../../../../components/SettingsMenu/QualityPresetSection.tsx";
import { SliderSection } from "../../../../components/SettingsMenu/SliderSection.tsx";
import { StyleIntensitySection } from "../../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { SwitchSection } from "../../../../components/SettingsMenu/SwitchSection.tsx";
import { TextSection } from "../../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../../components/SettingsMenuLayout.tsx";
import { scrollCreateWorkspaceGridToTop } from "../../../utils/scrollCreateWorkspaceGridToTop.ts";
import {
  useGenerateSettings,
  useGenerateSettingsAtom,
} from "../hooks/useGenerateSettings.ts";
import { useImageFromPromptGeneration } from "../hooks/useImageFromPromptGeneration.ts";
import { useIsFirstGenerationTooltipOpen } from "../hooks/useIsFirstGenerationTooltipOpen.ts";
import { generatePromptStore } from "../stores/generatePromptStore.ts";

export const GenerateSettings = () => {
  const {
    mutation: generateImageFromPrompt,
    isLoading: isImageFromPromptGenerationLoading,
  } = useImageFromPromptGeneration({
    onSuccess: scrollCreateWorkspaceGridToTop,
  });

  return (
    <Form
      className="flex-col flex-fill"
      schema={zGenerateSettings}
      initialValues={{
        prompt: "",
        selectedStyleUuid: null,
      }}
      onSubmit={(values) => {
        // FIXME: make the mutation async
        generateImageFromPrompt({
          prompt: values.prompt,
          styleUuid: values.selectedStyleUuid,
        });
        return Promise.resolve(true);
      }}
    >
      <GenerateSettingsFormContent
        isGenerationLoading={isImageFromPromptGenerationLoading}
      />
    </Form>
  );
};

const zGenerateSettings = z.object({
  // FIXME: use undefined instead of null for unselected selectedStyleUuid
  selectedStyleUuid: addNullInput(z.string().uuid(), "Please select a style"),
  prompt: z.string().min(1, { message: "Please enter a prompt" }),
});
type StyleGenerateValues = z.input<typeof zGenerateSettings>;

export const GenerateSettingsFormContent = ({
  isGenerationLoading,
}: {
  isGenerationLoading: boolean;
}) => {
  const { board } = useBoard();
  const { generateSettings, defaultGenerateSettings } = useGenerateSettings();
  const { prompt } = generatePromptStore.useState();
  const { user } = useUser();

  const { data: style } = useAppQuery<Style>({
    queryKey: board.last_used_style_uuid
      ? `styles/${board.last_used_style_uuid}`
      : null,
    enabled: !!board.last_used_style_uuid,
  });

  const { isOpen: isFirstGenerationTooltipOpen } =
    useIsFirstGenerationTooltipOpen.useState();

  const { setValues, submit, useError } = useForm<StyleGenerateValues>();
  const missingStyleError = useError((v) => v.selectedStyleUuid);

  useEffect(() => {
    setValues({ selectedStyleUuid: board.last_used_style_uuid });
  }, [setValues, board.last_used_style_uuid]);

  useEffect(() => {
    setValues({ prompt });
  }, [setValues, prompt]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <GenerationRatioPresetSection
            generationRatio={generateSettings.generation_ratio}
            onGenerationRatioChange={(generationRatio) =>
              useGenerateSettingsAtom.updateGenerateSettings(board.uuid, {
                generation_ratio: generationRatio,
              })
            }
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  defaultLoraScale={defaultGenerateSettings.lora_scale}
                  loraScale={generateSettings.lora_scale}
                  onLoraScaleChange={(loraScale: number) =>
                    useGenerateSettingsAtom.updateGenerateSettings(board.uuid, {
                      lora_scale: loraScale,
                    })
                  }
                />
                {/* FIXME: We currently display this option only for photographic flux style as we didn't test it for other type of styles. This can be extended in a second phase. */}
                {style?.images_kind === "photography" &&
                  style.model_architectures.includes("flux") && (
                    <SwitchSection
                      switchName="Style Enhancement"
                      switchInformationSection="Activate it to better catch your style details such as colors ... This is still experimental and can lead to some prompt inconsistencies."
                      value={generateSettings.enable_flux_prompt_enhancement}
                      onChange={(enable) =>
                        useGenerateSettingsAtom.updateGenerateSettings(
                          board.uuid,
                          {
                            enable_flux_prompt_enhancement: enable,
                          },
                        )
                      }
                      isBeta
                    />
                  )}
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={generateSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    useGenerateSettingsAtom.updateGenerateSettings(board.uuid, {
                      negative_prompt: negativePrompt,
                    })
                  }
                />
              </div>
            }
          />
          {user && isUserAllowedTo(user, "mode:debug") && (
            <div className="border-t">
              <CollapsableSettingsSectionWrapper
                name="Debug mode"
                content={
                  <div className="flex-col gap-200">
                    {style && (
                      <div className="flex-col gap-200 text-primary body-md-default">
                        Model Architecture
                        <div className="flex-row-center gap-100">
                          {style.model_architectures.map((architecture) => (
                            <PresetButton
                              key={architecture}
                              isSelected={
                                generateSettings.model_architecture ===
                                architecture
                              }
                              onClick={() =>
                                useGenerateSettingsAtom.updateGenerateSettings(
                                  board.uuid,
                                  {
                                    model_architecture: architecture,
                                  },
                                )
                              }
                            >
                              {architecture}
                            </PresetButton>
                          ))}
                          <PresetButton
                            key="default"
                            isSelected={
                              generateSettings.model_architecture === undefined
                            }
                            onClick={() =>
                              useGenerateSettingsAtom.updateGenerateSettings(
                                board.uuid,
                                {
                                  model_architecture: undefined,
                                },
                              )
                            }
                          >
                            Default
                          </PresetButton>
                        </div>
                      </div>
                    )}
                    <QualityPresetSection
                      value={generateSettings.quality_preset}
                      onChange={(preset) =>
                        useGenerateSettingsAtom.updateGenerateSettings(
                          board.uuid,
                          {
                            quality_preset: preset,
                          },
                        )
                      }
                    />
                    <SliderSection
                      value={Math.round(generateSettings.guidance_scale * 100)}
                      min={0}
                      max={100}
                      onChange={(guidanceScalePercentage) =>
                        useGenerateSettingsAtom.updateGenerateSettings(
                          board.uuid,
                          {
                            guidance_scale: guidanceScalePercentage / 100,
                          },
                        )
                      }
                      sliderName="Guidance scale"
                      defaultValue={
                        defaultGenerateSettings.guidance_scale
                          ? defaultGenerateSettings.guidance_scale * 100
                          : undefined
                      }
                      sliderInformationSection={
                        <div className="flex-col gap-200 body-md-default text-primary">
                          <div>
                            Following range will be applied to the guidance
                            scale:
                          </div>
                          <div>
                            - <span className="body-md-semibold">SDXL</span>: 0%
                            : 5, 100% : 9
                          </div>
                          <div>
                            -{" "}
                            <span className="body-md-semibold">
                              Flux (Photo)
                            </span>
                            : 0% : 2.5, 100% : 4.5
                          </div>
                          <div>
                            -{" "}
                            <span className="body-md-semibold">
                              Flux (Illustration)
                            </span>
                            : 0% : 4, 100% : 6
                          </div>
                        </div>
                      }
                    />
                  </div>
                }
              />
            </div>
          )}
        </div>
      }
      footer={
        <div className="relative flex-row-center">
          <GenerationBar
            isLoading={isGenerationLoading}
            onGenerate={submit}
            prompt={prompt}
            setPrompt={(newPrompt) => generatePromptStore.setPrompt(newPrompt)}
            promptError={useError((v) => v.prompt)}
            autoFocus
            showEnhancer
            extraErrors={missingStyleError ? [missingStyleError] : undefined}
            infoContent={
              <div className="flex-col gap-100 body-md-default text-primary">
                <div className="body-md-semibold">To get the best results:</div>
                <ul className="pl-400 list-disc">
                  <li>Write in English</li>
                  <li>Use short words separated by commas</li>
                  <li>
                    Respect the following prompt structure: subject, action,
                    background, other style attributes (lightning, camera
                    focus...)
                  </li>
                  <li>
                    Go to{" "}
                    <span className="body-md-semibold">
                      Advanced &gt; Exclude
                    </span>{" "}
                    to remove elements
                  </li>
                </ul>
              </div>
            }
          />
          {isFirstGenerationTooltipOpen && (
            <Popover
              side="right"
              arrowDisplayed
              updatePositionStrategy="always"
              isOpen={isFirstGenerationTooltipOpen}
              content={
                <div className="relative px-400 py-600 max-w-[260px] flex-col gap-200 text-primary body-md-default">
                  <BaseClickableIcon
                    className="absolute right-200 top-200"
                    name="X"
                    onClick={() => {
                      useIsFirstGenerationTooltipOpen.setIsOpen(false);
                    }}
                  />
                  <div className="label-lg-semibold">Time to Create!</div>
                  <div className="flex-row items-center flex-wrap gap-100">
                    {/*  FIXME: adapt the text to the future prompt enhancement design*/}
                    Describe what you want to create or enhance a prompt then
                    click on “Generate”.
                  </div>
                </div>
              }
              className="!p-0 !rounded-100 !shadow-400 bg-surface-primary-rest"
              arrowClassName="fill-button-primary-rest stroke-inverse-rest"
            >
              <div className="absolute right-0" />
            </Popover>
          )}
        </div>
      }
    />
  );
};
