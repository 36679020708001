import { useEffect, useState } from "react";
import { z } from "zod";
import { Form } from "../../../../../../components/Form/Form.tsx";
import { useForm } from "../../../../../../components/Form/FormContext.ts";
import { Switch } from "../../../../../../components/Switch/Switch.tsx";
import { useUser } from "../../../../../../hooks/useUser.ts";
import { useAppQuery } from "../../../../../../http/useAppQuery.ts";
import { isUserAllowedTo } from "../../../../../../types.ts";
import { addNullInput } from "../../../../../../utils/validations.ts";
import type { Style } from "../../../../../types.ts";
import { PresetButton } from "../../../../components/PresetButton.tsx";
import { useBoard } from "../../../../hooks/useBoard.ts";
import { useSelectedAsset } from "../../../../hooks/useSelectedAsset.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { QualityPresetSection } from "../../../components/SettingsMenu/QualityPresetSection.tsx";
import { SettingsSectionWrapper } from "../../../components/SettingsMenu/SettingsSectionWrapper.tsx";
import { SliderSection } from "../../../components/SettingsMenu/SliderSection.tsx";
import { StyleIntensitySection } from "../../../components/SettingsMenu/StyleIntensitySection.tsx";
import { TextSection } from "../../../components/SettingsMenu/TextSection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import { isEmpty } from "../../components/BaseEditor/utils.ts";
import {
  generateBrushMask,
  setBrushFreeDrawingBrush,
  updateExistingBrushColors,
} from "../../components/MaskEditor/brushUtils.ts";
import {
  generateLassosMask,
  setLassoFreeDrawingBrush,
  setOnCreateLasso,
  updateExistingLassosColors,
} from "../../components/MaskEditor/lassoUtils.ts";
import { FillModeToggle } from "./FillModeToggle.tsx";
import { GenerativeFillCreativityStrengthPresetSection } from "./GenerativeFillCreativityStrengthPresetSection.tsx";
import {
  GENERATIVE_FILL_DEFAULT_SETTINGS,
  useGenerativeFillSettings,
} from "./hooks/useGenerativeFillSettings.ts";
import { useUploadAndGenerativeFillMutation } from "./hooks/useUploadAndGenerativeFillMutation.ts";
import { generativeFillCanvasStore } from "./stores/generativeFillCanvasStore.ts";

export const GenerativeFillSettings = () => {
  const { mutate: fillMutate, isLoading: fillIsLoading } =
    useUploadAndGenerativeFillMutation({});

  const { fabricCanvas, fillMode } = generativeFillCanvasStore.useState();

  return (
    <Form
      className="flex-col flex-fill"
      schema={zGenerativeFillSettings}
      initialValues={{
        selectedAssetUuid: "",
        prompt: "",
        selectedStyleUuid: null,
        isCanvasEmpty: true,
        isSelectedImageSucceeded: false,
      }}
      onSubmit={async (values) => {
        if (fabricCanvas) {
          const maskBlob = await (fillMode === "lasso"
            ? generateLassosMask({ fabricCanvas })
            : generateBrushMask({ fabricCanvas }));

          fillMutate({
            mask: maskBlob,
            prompt: values.prompt,
            assetUuid: values.selectedAssetUuid,
            styleUuid: values.selectedStyleUuid,
          });
        }
      }}
    >
      <GenerativeFillSettingsFormContent isGenerationLoading={fillIsLoading} />
    </Form>
  );
};

const zGenerativeFillSettings = z.object({
  selectedAssetUuid: z
    .string({ required_error: "Please upload an image in the canvas" })
    .uuid(),
  selectedStyleUuid: addNullInput(z.string().uuid(), "Please select a style"),
  prompt: z.string().min(1, { message: "Please enter a prompt" }),
  isCanvasEmpty: z.boolean().refine((val) => !val, {
    message: "Please trace a zone on the image",
  }),
  isSelectedImageSucceeded: z.boolean().refine((val) => val, {
    message: "Please wait for your image to be generated",
  }),
});
type GenerativeFillSettingsValues = z.input<typeof zGenerativeFillSettings>;

const GenerativeFillSettingsFormContent = ({
  isGenerationLoading,
}: {
  isGenerationLoading: boolean;
}) => {
  const { fabricCanvas, fillMode, brushSize } =
    generativeFillCanvasStore.useState();
  const { user } = useUser();
  const { board } = useBoard();
  const { generativeFillSettings, setGenerativeFillSettings } =
    useGenerativeFillSettings();
  const { selectedAsset } = useSelectedAsset();

  const { data: style } = useAppQuery<Style>({
    queryKey: board.last_used_style_uuid
      ? `styles/${board.last_used_style_uuid}`
      : null,
    enabled: !!board.last_used_style_uuid,
  });

  const { useValue, setValues, submit, useError } =
    useForm<GenerativeFillSettingsValues>();
  const prompt = useValue((v) => v.prompt);
  const missingStyleError = useError((v) => v.selectedStyleUuid);
  const missingAssetError = useError((v) => v.selectedAssetUuid);
  let imageNotSucceededError = useError((v) => v.isSelectedImageSucceeded);
  let isCanvasEmptyError = useError((v) => v.isCanvasEmpty);
  if (imageNotSucceededError) isCanvasEmptyError = undefined;
  if (missingAssetError) imageNotSucceededError = undefined;

  useEffect(() => {
    setValues({ selectedStyleUuid: board.last_used_style_uuid });
  }, [setValues, board.last_used_style_uuid]);

  useEffect(() => {
    setValues({ selectedAssetUuid: selectedAsset?.uuid });
  }, [setValues, selectedAsset?.uuid]);

  useEffect(() => {
    setValues({
      isSelectedImageSucceeded: selectedAsset?.content.status === "succeeded",
    });
  }, [setValues, selectedAsset?.content.status]);

  useEffect(() => {
    if (fabricCanvas) {
      setValues({ isCanvasEmpty: true });
      const onAdd = () => setValues({ isCanvasEmpty: false });
      const onRemove = () =>
        setValues({ isCanvasEmpty: isEmpty(fabricCanvas) });

      fabricCanvas.on("object:added", onAdd);
      fabricCanvas.on("object:removed", onRemove);
      return () => {
        fabricCanvas.off("object:added", onAdd);
        fabricCanvas.off("object:removed", onRemove);
      };
    }
  }, [fabricCanvas, setValues]);

  const [advancedSectionOpen, setAdvancedSectionOpen] = useState(false);
  useEffect(() => {
    if (generativeFillSettings.creativity_strength_preset === "custom") {
      setAdvancedSectionOpen(true);
    }
  }, [generativeFillSettings.creativity_strength_preset]);

  // XXX: to change the selection opacity in fabric canvas
  useEffect(() => {
    if (fabricCanvas) {
      const fillColor = `rgba(2, 159, 245, ${generativeFillSettings.prompt_strength})`;
      if (fillMode === "lasso") {
        setLassoFreeDrawingBrush({ fabricCanvas });
        const onCreateLassoCleanUp = setOnCreateLasso({
          fabricCanvas,
          color: fillColor,
        });
        updateExistingLassosColors({
          fabricCanvas,
          color: fillColor,
        });
        return onCreateLassoCleanUp;
      } else {
        setBrushFreeDrawingBrush({
          fabricCanvas,
          color: fillColor,
          size: brushSize,
        });
        updateExistingBrushColors({ fabricCanvas, color: fillColor });
      }
    }
  }, [
    brushSize,
    fabricCanvas,
    fillMode,
    generativeFillSettings.prompt_strength,
  ]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          <SettingsSectionWrapper
            name="Selection"
            inline
            content={<FillModeToggle />}
          />
          <SettingsSectionWrapper
            name="Creativity Strength"
            content={<GenerativeFillCreativityStrengthPresetSection />}
            infoContent={
              <ul className="text-primary body-md-default list-disc p-200">
                <li>
                  Low Creativity is adapted for light modifications, results
                  will be close to your initial image.
                </li>
                <li>
                  High Creativity is adapted for strong modifications, results
                  can be very different from your initial image.
                </li>
              </ul>
            }
          />
          <CollapsableSettingsSectionWrapper
            name="Advanced"
            open={advancedSectionOpen}
            onOpenChange={setAdvancedSectionOpen}
            content={
              <div className="flex-col">
                <StyleIntensitySection
                  loraScale={generativeFillSettings.lora_scale}
                  onLoraScaleChange={(loraScale) =>
                    setGenerativeFillSettings({
                      lora_scale: loraScale,
                    })
                  }
                  defaultLoraScale={GENERATIVE_FILL_DEFAULT_SETTINGS.lora_scale}
                />
                <SliderSection
                  min={0}
                  max={100}
                  value={Math.round(
                    generativeFillSettings.prompt_strength * 100,
                  )}
                  onChange={(promptStrengthPercentage) =>
                    setGenerativeFillSettings({
                      prompt_strength: promptStrengthPercentage / 100,
                      creativity_strength_preset: "custom",
                    })
                  }
                  sliderName="Creativity"
                  sliderInformationSection={
                    <div className="text-primary body-md-default">
                      <span className="body-md-semibold">Creativity</span> -
                      Lower values will lead to similar images. Higher values
                      will lead to more creative images.
                    </div>
                  }
                />
                <TextSection
                  title="Exclude"
                  valuePlaceholder="illustration, wonderful chilli pepper, vivid colors..."
                  value={generativeFillSettings.negative_prompt}
                  onValueChange={(negativePrompt: string) =>
                    setGenerativeFillSettings({
                      negative_prompt: negativePrompt,
                    })
                  }
                />
              </div>
            }
          />
          {user && isUserAllowedTo(user, "mode:debug") && (
            <div className="border-t">
              <CollapsableSettingsSectionWrapper
                name="Debug mode"
                content={
                  <div className="flex-col gap-200">
                    {style && (
                      <div className="flex-col text-primary body-md-default gap-200">
                        Model Architecture
                        <div className="flex-row-center gap-100">
                          {style.model_architectures.map((architecture) => (
                            <PresetButton
                              key={architecture}
                              isSelected={
                                generativeFillSettings.model_architecture ===
                                architecture
                              }
                              onClick={() =>
                                setGenerativeFillSettings({
                                  model_architecture: architecture,
                                })
                              }
                            >
                              {architecture}
                            </PresetButton>
                          ))}
                          <PresetButton
                            key="default"
                            isSelected={
                              generativeFillSettings.model_architecture ===
                              undefined
                            }
                            onClick={() =>
                              setGenerativeFillSettings({
                                model_architecture: undefined,
                              })
                            }
                          >
                            Default
                          </PresetButton>
                        </div>
                      </div>
                    )}
                    <QualityPresetSection
                      value={generativeFillSettings.quality_preset}
                      onChange={(preset) =>
                        setGenerativeFillSettings({ quality_preset: preset })
                      }
                    />
                    <div className="flex-col gap-200">
                      <SliderSection
                        value={Math.round(
                          generativeFillSettings.guidance_scale * 100,
                        )}
                        min={0}
                        max={100}
                        onChange={(guidanceScalePercentage) =>
                          setGenerativeFillSettings({
                            guidance_scale: guidanceScalePercentage / 100,
                          })
                        }
                        sliderName="Guidance Scale"
                        defaultValue={
                          GENERATIVE_FILL_DEFAULT_SETTINGS.guidance_scale * 100
                        }
                        sliderInformationSection={
                          <div className="flex-col text-primary body-md-default gap-200">
                            <div>
                              Following range will be applied to the guidance
                              scale:
                            </div>
                            <div>
                              - <span className="body-md-semibold">SDXL</span>:
                              0% : 5, 100% : 9
                            </div>
                            <div>
                              -{" "}
                              <span className="body-md-semibold">
                                Flux (Photo)
                              </span>
                              : 0% : 2.5, 100% : 4.5
                            </div>
                            <div>
                              -{" "}
                              <span className="body-md-semibold">
                                Flux (Illustration)
                              </span>
                              : 0% : 4, 100% : 6
                            </div>
                          </div>
                        }
                      />
                      <SliderSection
                        min={0}
                        max={300}
                        defaultValue={
                          GENERATIVE_FILL_DEFAULT_SETTINGS.padding_mask_crop
                        }
                        value={generativeFillSettings.padding_mask_crop}
                        onChange={(paddingMaskCrop) =>
                          setGenerativeFillSettings({
                            padding_mask_crop: paddingMaskCrop,
                          })
                        }
                        sliderName="Padding Mask Crop"
                        displayPercentage={false}
                      />
                      <div className="flex-row w-full text-primary body-md-default justify-between">
                        Auto patch
                        <Switch
                          value={generativeFillSettings.auto_patch}
                          onChange={(autoPatch: boolean) =>
                            setGenerativeFillSettings({
                              auto_patch: autoPatch,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>
                }
              />
            </div>
          )}
        </div>
      }
      footer={
        <GenerationBar
          prompt={prompt}
          setPrompt={(newPrompt) => {
            setValues({ prompt: newPrompt });
          }}
          promptError={useError((v) => v.prompt)}
          sectionTitle="Replace by"
          buttonContent="Replace"
          placeholder="a chilli pepper with sunglasses"
          isLoading={isGenerationLoading}
          onGenerate={submit}
          extraErrors={[
            isCanvasEmptyError,
            missingAssetError,
            missingStyleError,
            imageNotSucceededError,
          ].filter((error): error is string => error !== undefined)}
        />
      }
    />
  );
};
