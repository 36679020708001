import type { AxiosResponse } from "axios";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { useGenerateSettings } from "./useGenerateSettings.ts";

export const useImageFromPromptGeneration = ({
  onSuccess,
}: {
  onSuccess?: (
    response: AxiosResponse<{
      assets_uuids: string[];
    }>,
  ) => void;
}) => {
  const { generateSettings } = useGenerateSettings();
  const { board } = useBoard();
  const generateImageFromPromptMutation = useAppMutation<{
    assets_uuids: string[];
  }>({
    path: "boards/generate-image-from-prompt",
    invalidate: [`boards/${board.uuid}`, "boards", "users/me"],
    onSuccess,
  }).mutation;

  return {
    mutation: ({
      prompt,
      styleUuid,
    }: {
      prompt: string;
      styleUuid: string;
    }) => {
      generateImageFromPromptMutation.mutate({
        board_uuid: board.uuid,
        prompt,
        style_uuid: styleUuid,
        num_generations: generateSettings.num_generations,
        lora_scale: generateSettings.lora_scale,
        negative_prompt: generateSettings.negative_prompt,
        quality_preset: generateSettings.quality_preset,
        generation_ratio: generateSettings.generation_ratio,
        model_architecture: generateSettings.model_architecture,
        guidance_scale: generateSettings.guidance_scale,
        enable_flux_prompt_enhancement:
          generateSettings.enable_flux_prompt_enhancement,
      });
    },
    isLoading: generateImageFromPromptMutation.isLoading,
  };
};
