import {
  GENERATE_TOOL,
  SELECT_TOOL,
  TRANSFER_TOOL,
  UPSCALE_TOOL,
  VARIATION_TOOL,
} from "../../routes.ts";
import type { Tool } from "./types.ts";

export const FAVORITE_TAG_NAME = "__favorite__";

export const IMAGE_GENERATION_MODEL_TYPE = [
  "text_to_image",
  "image_to_image",
  "upscaler",
  "generative_fill",
  "style_transfer",
] as const;

export const GENERATION_RATIOS = [
  "r_1_1",
  "r_9_16",
  "r_3_4",
  "r_4_3",
  "r_16_9",
] as const;

export const NODE_STATUS = [
  "running",
  "succeeded",
  "failed",
  "not_running",
] as const;

export const QUALITY_PRESETS = ["fast", "high"] as const;

export const MODEL_ARCHITECTURES = ["sdxl", "flux"] as const;

export const TOOLS = [
  GENERATE_TOOL,
  TRANSFER_TOOL,
  VARIATION_TOOL,
  SELECT_TOOL,
  UPSCALE_TOOL,
] as const;

// XXX: Those tool names are used for tracking purposes (mixpanel `generation_type` property),
// and match the ones defined in the backend.
export const TOOL_TRACKING_NAMES: Record<Tool, string> = {
  generate: "Create",
  transfer: "StyleTransfer",
  variation: "Variation",
  select: "Fill",
  upscale: "Upscale",
} as const;
